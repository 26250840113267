#homePage {
	section#bgHome {
		background-image: url(../img/bg_homepage.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		height: calc(100vh - 70px);
		grid-column: 1/13;
		grid-row: 1;
		margin: 0;
		
		/* Grid container for the paragraph */
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		
		p {
			font-family: "Roboto Condensed", sans-serif;
			color: $color-secondary;
			font-weight: bold;
			letter-spacing: 1px;
			overflow: scroll;
			scrollbar-width: none;
			
			&::-webkit-scrollbar {
				display: none;
			}
			
			@media screen and (max-width: 550px) {
				& {
					margin-top: 25vw;
					font-size: 3.5vw;
					line-height: 32px;
					grid-column: 2/8;
				}
			}
			@media screen and (min-width: 550px) and (max-width: 768px) {
				& {
					margin-top: 21vw;
					font-size: 2.8vw;
					line-height: 36px;
					grid-column: 2/7;
				}
			}
			@media screen and (min-width: 768px) and (max-width: 980px) {
				& {
					margin-top: 12vw;
					font-size: 2.1vw;
					line-height: 30px;
					grid-column: 2/5;
				}
			}
			@media screen and (min-width: 980px) and (max-width: 1200px) {
				& {
					margin-top: 15vw;
					font-size: 1.6vw;
					line-height: 38px;
					grid-column: 2/5;
				}
			}
			@media screen and (min-width: 1200px) {
				& {
					margin-top: 17vw;
					font-size: 1.5vw;
					line-height: 30px;
					letter-spacing: 1px;
					grid-column: 2/4;
				}
			}
		}
	}
	
	section#services {
		grid-row: 2;
		
		@media screen and (max-width: 550px) {
			& {
				grid-column: 2/12;
			}
		}
		@media screen and (min-width: 550px) {
			& {
				grid-column: 4/10;
			}
		}
	}
	
	section#servicesDetails {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-gap: 2.5em;
		grid-row: 3;
		grid-column: 2/12;
		
		.serviceItem {
			text-align: center;
			
			img {
				width: 50%;
				object-fit: scale-down;
			}
			
			p {
				text-align: initial;
				
				@media screen and (max-width: 500px) {
					& {
						padding: 0 20px;
					}
				}
				
				span {
					margin-bottom: 5px;
					display: block;
					font-weight: bold;
					font-size: 1.25em;
				}
			}
		}
	}
	
	section#network-homePage {
		grid-row: 4;
		
		@media screen and (max-width: 550px) {
			& {
				grid-column: 2/12;
			}
		}
		@media screen and (min-width: 550px) {
			& {
				grid-column: 4/10;
			}
		}
	}
	
	section#call2action-homePage {
		display: grid;
		grid-column: 2/12;
		grid-row: 5;
		grid-template-columns: 5fr 1fr;
		
		@media screen and (max-width: 768px) {
			& {
				display: none;
			}
		}
		
		#call2action_ribbon {
			display: flex;
			padding-top: 80px;
			padding-left: 52px;
			background: url(../img/call2action_ribbon.jpg) no-repeat left center,
			url(../img/call2action.png) repeat-x center left;
			
			h3 {
				font-family: 'Pathway Gothic One', Helvetica, Arial, "Trebuchet MS", sans-serif;
				letter-spacing: 2px;
				line-height: 100%;
				flex: 5;
				margin: 0 30px 0 20px;
				
				@media screen and (min-width: 768px) and (max-width: 980px) {
					& {
						font-size: 25px;
						margin-top: -5px;
					}
				}
				@media screen and (min-width: 980px) and (max-width: 1200px) {
					& {
						font-size: 29px;
						margin-top: -5px;
					}
				}
				@media screen and (min-width: 1200px) {
					& {
						font-size: 29px;
						margin-top: 5px;
					}
				}
			}
			
			#call2action_text {
				flex: 7;
				margin-top: -5px;
				
				span {
					display: block;
					font-size: 22px;
					letter-spacing: 0;
					line-height: 130%;
					
					@media screen and (min-width: 768px) and (max-width: 980px) {
						& {
							font-size: 15px;
						}
					}
					@media screen and (min-width: 980px) and (max-width: 1200px) {
						& {
							font-size: 18px;
						}
					}
					@media screen and (min-width: 1200px) {
						& {
							font-size: 21px;
						}
					}
				}
			}
		}
		
		#call2action_ribbon_prop {
			width: 206px;
			height: 207px;
			position: relative;
			text-align: center;
			background: url(../img/call2action_circle_bg.png) left no-repeat;
			
			.inside {
				margin-top: 45px;
			}
			
			span:nth-child(odd) {
				font-size: 16px;
				font-style: italic;
				font-family: times, serif;
			}
			
			.request {
				font-family: 'Pathway Gothic One', Helvetica, Arial, "Trebuchet MS", sans-serif;
				font-size: 2.75em;
				line-height: 100%;
				margin-top: 10px;
				letter-spacing: 3px;
				display: block;
			}
			
			.ribbon_prop_1 {
				position: absolute;
				background: url(../img/call2action_circle.png) left 0 no-repeat;
				width: 206px;
				height: 205px;
				z-index: 2;
				
				&:hover {
					opacity: 0;
					cursor: pointer;
				}
				
				.inside span {
					color: $color-secondary;
				}
			}
			
			.ribbon_prop_2 {
				position: absolute;
				background: url(../img/call2action_circle.png) left -205px no-repeat;
				width: 206px;
				height: 205px;
				
				&:hover {
					opacity: 1;
				}
				
				.inside span {
					color: $color-primary;
				}
			}
		}
	}
}