#contactPage {
	#contact-hero {
		margin-bottom: 50px;
	}
	
	#contact-info {
		grid-row: 2;
		
		@media screen and (max-width: 980px) {
			& {
				grid-column: 2/12;
			}
			
			.contact-subtitles {
				font-size: 1.5em;
				margin-top: 60px;
			}
		}
		@media screen and (min-width: 980px) {
			& {
				grid-column: 2/7;
				padding-right: 6%;
			}
			
			.contact-subtitles {
				font-size: 1.5em;
				margin-top: 100px;
			}
		}
		
		.contact-icons {
			width: 1.5em;
			margin: 5px 10% 0 0;
		}
	}
	
	#contact-form {
		@media screen and (max-width: 980px) {
			& {
				grid-column: 2/12;
				grid-row: 3;
			}
		}
		@media screen and (min-width: 980px) {
			& {
				grid-column: 7/12;
				grid-row: 2;
				margin-top: 128px;
				padding-left: 6%;
			}
		}
		button {
			float: right;
		}
	}
}