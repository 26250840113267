body {
	background: url('../img/background.jpg') repeat left top;
	color: $color-primary;
	line-height: 180%;
	margin: auto;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	#root {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		
		.loader {
			svg {
				width: 100%;
				height: 50vh;
				position: fixed;
				top: 25%;
				left: 0;
			}
		}
		
		main {
			grid-row: 2;
			grid-column: 1/13;
			
			/* Page Fading Transition */
			.pageContainer-enter {
				opacity: 0;
				transform: scale(1.01);
			}
			
			.pageContainer-enter-active {
				opacity: 1;
				transform: scale(1);
				transition: opacity 200ms, transform 200ms;
			}
			
			.pageContainer-exit {
				opacity: 1;
				transform: scale(1);
			}
			
			.pageContainer-exit-active {
				opacity: 0;
				transform: scale(0.99);
				transition: opacity 200ms, transform 200ms;
			}
			/**/
			
			& .page {
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				font-family: "Roboto Slab", serif;
				font-size: 17px;
				letter-spacing: 0.3px;
				min-height: 700px;
				
				&--xs {
					@media screen and (max-width: $screen-xs-max) {
						padding-top: 60px;
					}
				}
				
				.hero {
					margin-top: 30px;
					grid-row: 1;
					
					p {
						font-family: "Roboto Condensed", sans-serif;
						font-style: normal;
						font-weight: 400;
						line-height: 160%;
						margin-bottom: 30px;
					}
					@media screen and (max-width: 550px) {
						& {
							font-size: 20px;
							grid-column: 2/12;
						}
					}
					@media screen and (min-width: 550px) and (max-width: 768px){
						& {
							font-size: 23px;
							grid-column: 2/12;
						}
					}
					@media screen and (min-width: 768px) {
						& {
							font-size: 23px;
							grid-column: 4/10;
						}
					}
				}
				
				section {
					margin: 30px 0;
				}
			}
		}
	}
}

/* Elements */
.hr {
	clear: both;
	height: 6px;
	background: url('../img/border.png') top repeat-x;
	width: 100%;
	
	hr {
		display: none;
	}
}

.hr75pc {
	clear: both;
	height: 6px;
	background: url(../img/border.png) top repeat-x;
	width: 75%;
}

a {
	color: $color-link;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	
	&:hover {
		color: $color-link-hover;
	}
}

/* Trick to avoid scrapping bots */
.e-mail:before {
	content: attr(data-website) "\0040" attr(data-user);
	unicode-bidi: bidi-override;
	direction: rtl;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: $color-primary solid 1px;
}
input[type="radio"] {
  outline: none;
}

button {
	&.btn-yellow {
		background-color: $color-tertiary;
		box-shadow: 0 0 2px 2px #5a626740;
		border: none;
		display: block;
		font-family: "Pathway Gothic One", sans-serif;
		font-size: 1.25em;
		height: 36px;
		letter-spacing: 1px;
		-webkit-font-smoothing: subpixel-antialiased;

		@media screen and (max-width: 980px) {
			& {
				width: 100%;
			}
		}
		@media screen and (min-width: 980px) {
			& {
				width: 50%;
			}
		}
		
		&:hover {
			cursor: pointer;
			box-shadow: 0 0 15px 2px #5a626782;
		}
	}
	
	&.btn-link {
		background: none;
		border: none;
		color: $color-primary;
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&:hover {
			color: $color-link-hover;
		}
	}
}

.form {
	& input, textarea {
		box-sizing: border-box;
		display: block;
		font-size: 1em;
		width: 100%;
		border: $color-primary solid 1px;
		padding: 10px;
	}
	
	& p {
		margin: 0;
		height: 35px;
		color: $color-danger;
	}
}

.bold {
	font-weight: bold;
}