#resourcesPage {
	section#projects {
		grid-column: 2/12;
		grid-row: 2;
		
		.mt55 {
			margin-top: 55px;
		}
		
		#nespressoLightningDesc {
			video {
				border: 10px solid $color-secondary;
				display: block;
				margin: 40px auto;
				
				@media screen and (max-width: 550px) {
					& {
						width: 300px;
					}
				}
				@media screen and (min-width: 550px) and (max-width: 768px) {
					& {
						width: 450px;
					}
				}
				
				@media screen and (min-width: 768px) and (max-width: 980px) {
					& {
						width: 600px;
					}
				}
				@media screen and (min-width: 980px) {
					& {
						width: 700px;
					}
				}
			}
			
			#NespressoLightningPrice1 {
				display: block;
				font-family: "Roboto Condensed", sans-serif;
				font-weight: bold;
				font-size: 22px;
				text-align: center;
			}
			
			#NespressoLightningPrice2 {
				display: block;
			}
		}
	}
	
	section#slides {
		grid-column: 2/12;
		grid-row: 3;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		
		h2 {
			width: 100%;
			margin-bottom: 1em;
		}
		
		.slide {
			height: 169px;
			min-width: 100px;
			margin-bottom: 50px;
			flex: 0 0 285px;
			
			img {
				width: 100%;
			}
			
			.slide__btn {
				text-align: center;
				line-height: 140%;
				outline: none;
				width: 100%;
				border-radius: 0.1px;
				cursor: pointer;
				margin-top: 5px;
				background-color: rgba(0, 0, 0, 0);
				color: $color-secondary;
				-webkit-transition: all 0.5s;
				-moz-transition: all 0.5s;
				-o-transition: all 0.5s;
				transition: all 0.5s;
				border: 1px groove rgba(57, 68, 74, 0.3);
				position: relative;
				
				&:hover::before {
					opacity: 1;
					-webkit-transform: scale(1, 1);
					transform: scale(1, 1);
				}
				
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					opacity: 0;
					background-color: rgba(255, 255, 255, 0.5);
					-webkit-transition: all 0.4s;
					-moz-transition: all 0.4s;
					-o-transition: all 0.4s;
					transition: all 0.4s;
					-webkit-transform: scale(0.5, 1);
					transform: scale(0.5, 1);
				}
				
				a, button {
					color: #39444a;
					display: block;
					font-weight: bold;
					font-family: "Pathway Gothic One", serif;
					font-size: 14px;
					height: 100%;
					letter-spacing: 10px;
					text-decoration: none;
					width: 100%;
					z-index: 2;
				}
			}
		}
		
		.slide--fake {
			img {
				filter: blur(0.1rem);
				max-width: 300px;
			}
			
			button {
				line-height: 140%;
			}
			
			.slide__btn::before {
				background-color: rgba(201, 24, 0, 0.5);
			}
		}
		
		/* Trick for positioning last element correctly */
		.extraItem {
			width: 0;
			height: 0;
			margin: 0;
			padding: 0;
		}
		
		.slidesDesc {
			margin-top: 30px;
			width: 100%;
		}
	}
}